import React from 'react';
import firebase from 'firebase';
import logo from './logo.svg';
import './App.css';

const firebaseConfig = {
  apiKey: "AIzaSyCj5YAYexN1jAGvayjtNj6ZlRr0D8LtBnU",
  authDomain: "postmormon-c366e.firebaseapp.com",
  databaseURL: "https://postmormon-c366e.firebaseio.com",
  projectId: "postmormon-c366e",
  storageBucket: "postmormon-c366e.appspot.com",
  messagingSenderId: "229848088801",
  appId: "1:229848088801:web:27ee43b83741b5c0"
};

firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>PostMormon.org</h1>
        {/*<p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>*/}
        
      </header>
      <div className="App-body">
        <p> 
          September 10,  2019
        </p>
        <p>
          In early 2017 PostMormon.org was hacked, so the website was shut down to prevent the 
          hackers from gaining access to the membership database. The website was backed 
          up so it could be fully restored; however, also beginning in early 2017, browser 
          manufacturers began to adopt new policies that made restoration of the website 
          extremely difficult and time consuming. 
        </p>
        <p>
          To make matters worse, in 2018 the UK government adopted new laws that made it 
          virtually impossible to restore the website in a form that complies with the new laws. 
          These laws are applicable to PostMormom.org because it has many registered members who reside the UK.
        </p>
        <p>
          So, the bad news is PostMormon.org will not be fully restored as intended. The good news is, 
          an all new mobile friendly version is under consideration that could become a reality.  
          Check back here later for further updates.
        </p>
        <p>
          Sincerely,<br />
          Jeff Ricks<br />
          Founder of PostMormon.org
        </p>
      </div>
    </div>
  );
}

export default App;
